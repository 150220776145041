<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" @click="handleCreate('')">
        新增
      </el-button>
      <el-input
        v-model="listQuery.keyword"
        placeholder="请输入曲谱名称/作者名"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.is_on"
        placeholder="状态"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option label="正常" :value="1" />
        <el-option label="已下架" :value="0" />
      </el-select>
      <el-select
        v-model="listQuery.free_type"
        placeholder="收费类型"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option label="免费" :value="1" />
        <el-option label="会员免费" :value="2" />
        <el-option label="会员收费" :value="3" />
      </el-select>
      <el-button class="filter-item" type="primary" @click="handleFilter">
        搜索
      </el-button>
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
          <!-- {{ scope.row.id }} -->
        </template>
      </el-table-column>
      <el-table-column label="曲谱名称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column label="作者" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.author }}
        </template>
      </el-table-column>
      <el-table-column label="收费类型" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.free_type == 1" type="success">免费</el-tag>
          <el-tag v-else-if="scope.row.free_type == 2" type="danger">会员免费</el-tag>
          <el-tag v-else type="danger">会员收费</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="价格" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.price }}
        </template>
      </el-table-column>
      <el-table-column label="购买人数" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.buy_num }}
        </template>
      </el-table-column>
      <el-table-column label="查看人数" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.view_num }}
        </template>
      </el-table-column>
      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_on == 1" type="success">正常</el-tag>
          <el-tag v-else type="danger">已下架</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="序号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.sort_weight }}
        </template>
      </el-table-column>

      <el-table-column
        label="操作"
        width="280"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleCreate(scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="scope.row.is_on == 1"
            size="mini"
            type="danger"
            @click="handleStatus(scope.row.id, '下架')"
            >下架</el-button
          >
          <el-button
            v-else
            size="mini"
            type="success"
            @click="handleStatus(scope.row.id, '上架')"
            >上架</el-button
          >
          <el-button
            size="mini"
            type="warning"
            v-if="scope.row.free_type==2"
            @click="handleGoList(scope.row)"
          >
            购买人列表
          </el-button>
          <el-button
        size="mini"
        type="primary"
         @click="dialogVisible(scope.row)"
        >同步教学视频
      </el-button>
      
    </el-upload>
    
    
        </template>
        
      </el-table-column>
      
    </el-table>

    

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>


   
  </div>
</template>

<script>

import request from "@/utils/request";
import uploadVideo from '@/components/UploadVideo/index.vue';


export default {
    components: {

    uploadVideo
  },
  data() {
    return {
      radio:'1',
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        title: "",
        is_on: "",
        is_index_show: "",
        video:"",
      },
      btnLoading: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
        done();
        })
        .catch(_ => {});
      },
    dialogVisible(row){
      this.$router.push(`/music/music_videolist?id=${row.id}`);
    },
    handleGoList(row) {
      this.$router.push(`/music/pay_list?id=${row.id}`);
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/backend/musicScore/list",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleCreate(row) {
      this.$router.push(`/music/music_edit?id=${row.id}`);
    },
    //上下架
    handleStatus(val, text) {
      this.$confirm("此操作将" + text + "该课程类型, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/musicScore/upDown",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
    //删除
    handleShow(val, text) {
      this.$confirm("此操作将课程类型" + text + ", 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/course/categoryIndexShow",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
